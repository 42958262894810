<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <section class="webgl-module-component">
    <TresCanvas
      ref="tresCanvas"
      :disableRender="true">
      <TresPerspectiveCamera
        :position="[-150, 250, 300]"
        :fov="75"
        :near="0.1"
        :far="1000" />
      <Suspense>
        <TresGroup
          v-if="mesh && yellow && aqua"
          ref="masterGroup"
          :position="[30, 300, 0]">
          <TresGroup ref="scrollGroup">
            <TresGroup
              v-if="mesh && yellow && aqua"
              ref="group">
              <TresMesh
                v-if="mesh"
                ref="meshObject">
                <TresBufferGeometry :position="[mesh, 3]" />
                <TresMeshBasicMaterial
                  color="#61f5f6"
                  transparent
                  doubleSided
                  :opacity="meshOpacity"
                  wireframe />
              </TresMesh>
              <TresMesh v-if="yellow">
                <TresBufferGeometry :position="[yellow, 3]" />
                <TresMeshBasicMaterial
                  color="#fefb96"
                  :transparent="true"
                  :_alpha-test="0.005"
                  :opacity="yellowOpacity" />
              </TresMesh>
              <TresMesh v-if="aqua">
                <TresBufferGeometry :position="[aqua, 3]" />
                <TresMeshBasicMaterial
                  color="#61f5f6"
                  :transparent="true"
                  :_alpha-test="0.005"
                  :opacity="aquaOpacity" />
              </TresMesh>
            </TresGroup>
          </TresGroup>
        </TresGroup>
      </Suspense>
    </TresCanvas>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "WebglComponent",
    "CLASSNAME": "webgl-module-component",
    "URL": "http://vuevitewp.local/"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import * as THREE from 'three'

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js'
import { TresCanvas, useRenderLoop, useLoader, useTexture } from '@tresjs/core'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { onBeforeMount, onMounted, shallowRef, watch, toRaw } from 'vue'
import gsap, { Power4 } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import helix from '@/assets/3D/helix3.fbx?url'
import useUtils from '@/components/composables/useUtils.js'
import useIntroShown from '@/components/composables/useIntroShown.js'
import bgImage from '@/assets/img/bg-home.jpg'
import ball from '@/assets/img/ball.png?url'
import { LensDistortionShader } from '../../assets/js/lensDistortion'
// import useModulesReady from '@/components/composables/useModulesReady.js'

// const { removePendingModule } = useModulesReady()

gsap.registerPlugin(ScrollTrigger)

const { scrollProgress } = useUtils()
const { introShown } = useIntroShown()
const { onLoop } = useRenderLoop()

const particles = shallowRef()
const masterGroup = shallowRef()
const scrollGroup = shallowRef()
const group = shallowRef()
const mesh = shallowRef()
const meshOpacity = shallowRef(0)
const aqua = shallowRef()
const aquaOpacity = shallowRef(0)
const yellow = shallowRef()
const yellowOpacity = shallowRef(0)
const sceneReady = shallowRef(false)
const particleTexture = shallowRef()
const tresCanvas = shallowRef()
const composer = shallowRef()
const distortPass = shallowRef()
const meshObject = shallowRef()

let positions = []
let velocities = []
const particleCount = 3500
const maxRange = 2000
const minRange = maxRange / 2
const minHeight = 50
const particleGeo = new THREE.BufferGeometry()
const TextureLoader = new THREE.TextureLoader()

function addParticles() {
  for (let i = 0; i < particleCount; i++) {
    positions.push(
      Math.floor(Math.random() * maxRange - minRange),
      Math.floor(Math.random() * minRange + minHeight),
      Math.floor(Math.random() * maxRange - minRange)
    )
    velocities.push(
      Math.floor(Math.random() * 6 - 3) * 0.1,
      Math.floor(Math.random() * 5 + 0.12) * 0.018,
      Math.floor(Math.random() * 6 - 3) * 0.1
    )
  }
  particleGeo.setAttribute(
    'position',
    new THREE.Float32BufferAttribute(positions, 3)
  )
  particleGeo.setAttribute(
    'velocity',
    new THREE.Float32BufferAttribute(velocities, 3)
  )
  const particleMaterial = new THREE.PointsMaterial({
    size: 2,
    color: 0x61f5f6,
    map: TextureLoader.load(ball),
    blending: THREE.AdditiveBlending,
    depthTest: false,
    transparent: true,
    opacity: 0.3,
  })
  particles.value = new THREE.Points(particleGeo, particleMaterial)
  tresCanvas.value.context.scene.value.add(toRaw(particles.value))
}

function updateParticles() {
  for (let i = 0; i < particleCount * 3; i += 3) {
    particles.value.geometry.attributes.position.array[i] -=
      particles.value.geometry.attributes.velocity.array[i] + 0.1
    particles.value.geometry.attributes.position.array[i + 1] -=
      particles.value.geometry.attributes.velocity.array[i + 1] + 0.1
    particles.value.geometry.attributes.position.array[i + 2] -=
      particles.value.geometry.attributes.velocity.array[i + 2]
    if (particles.value.geometry.attributes.position.array[i + 1] < -200) {
      particles.value.geometry.attributes.position.array[i] = Math.floor(
        Math.random() * maxRange - minRange
      )
      particles.value.geometry.attributes.position.array[i + 1] = Math.floor(
        Math.random() * minRange + minHeight
      )
      particles.value.geometry.attributes.position.array[i + 2] = Math.floor(
        Math.random() * maxRange - minRange
      )
    }
  }
  particles.value.geometry.attributes.position.needsUpdate = true
}

onLoop(({ delta }) => {
  updateParticles()
  if (masterGroup.value) {
    masterGroup.value.rotation.y += delta * 0.05
  }
  toRaw(composer.value).render()
  distortPass.value.enabled = true
  distortPass.value.material.uniforms.baseIor.value = 0.95
  distortPass.value.material.uniforms.bandOffset.value = 0.0014
  distortPass.value.material.uniforms.jitterOffset.value += 0.01
  distortPass.value.material.uniforms.jitterIntensity.value = 1.0
})

const loadModel = async () => {
  await useLoader(FBXLoader, helix)
    .then((fbx) => {
      mesh.value = fbx.children[0].geometry.attributes.position.array
      yellow.value = fbx.children[1].geometry.attributes.position.array
      aqua.value = fbx.children[2].geometry.attributes.position.array
    })
    .catch((err) => {
      console.log(err)
      setTimeout(() => {
        console.log('Waiting for FBX to load...')
        loadModel()
      }, 100)
    })
}

onBeforeMount(async () => {
  await loadModel()
  particleTexture.value = await useTexture({ map: ball })

  sceneReady.value = true
})

const introAnimation = () => {
  gsap.to(group.value.position, {
    ease: Power4.easeInOut,
    y: -150,
    duration: 3,
  })
  gsap.to(group.value.rotation, {
    ease: Power4.easeInOut,
    y: 1.3,
    duration: 3,
  })
  gsap.to(aquaOpacity, {
    ease: Power4.easeInOut,
    value: 0.2,
    duration: 3,
  })
  gsap.to(yellowOpacity, {
    ease: Power4.easeInOut,
    value: 0.2,
    duration: 3,
    onComplete: () => {
      scrollAnimations()
    },
  })
}

const scrollAnimations = () => {
  gsap.to(meshOpacity, {
    ease: Power4.easeInOut,
    value: 0.1,
    scrollTrigger: {
      trigger: '#app',
      start: 'top-=500 center',
      end: 'bottom+=100 center',
      scrub: true,
      markers: false,
    },
  })
  gsap.fromTo(
    aquaOpacity,
    {
      value: 0.2,
    },
    {
      ease: Power4.easeInOut,
      value: 0,
      scrollTrigger: {
        trigger: '#app',
        start: 'top center',
        end: 'bottom-=500 center',
        scrub: true,
        markers: false,
      },
    }
  )
  gsap.fromTo(
    yellowOpacity,
    {
      value: 0.2,
    },
    {
      ease: Power4.easeInOut,
      value: 0,
      scrollTrigger: {
        trigger: '#app',
        start: 'top-=500 center',
        end: 'bottom+=100 center',
        scrub: true,
        markers: false,
      },
    }
  )

  gsap.to(masterGroup.value.position, {
    ease: Power4.easeInOut,
    y: 600,
    scrollTrigger: {
      trigger: `#app`,
      start: 'center-=10% center',
      end: 'center+=40% center',
      scrub: true,
      markers: false,
    },
  })
}

const animations = () => {
  introAnimation()
}

const isSceneReady = () => {
  if (!introShown.value) {
    setTimeout(() => {
      console.log('Wating for intro to complete...')
      isSceneReady()
    }, 100)
  } else {
    if (!sceneReady.value) {
      setTimeout(() => {
        console.log('Waiting for 3D scene to be ready...')
        isSceneReady()
      }, 100)
    } else {
      // removePendingModule('DNAmesh')
      meshObject.value.rotation.y = -0.15
      meshObject.value.position.x = -15
      animations()
    }
  }
}

watch(scrollProgress, (val) => {
  if (scrollGroup.value) {
    gsap.to(scrollGroup.value.rotation, {
      ease: Power4.easeInOut,
      y: val * 7,
      duration: 0.01,
    })
    gsap.to(particles.value.rotation, {
      ease: Power4.easeInOut,
      y: val * 1,
      duration: 0.01,
    })
  }
})

const mountBackground = () => {
  const TextureLoader = new THREE.TextureLoader()
  const bgTexture = TextureLoader.load(bgImage)
  bgTexture.colorSpace = THREE.SRGBColorSpace
  tresCanvas.value.context.scene.value.background = bgTexture
}

const addRenderPasses = () => {
  // render pass
  const renderPass = new RenderPass(
    tresCanvas.value.context.scene.value,
    tresCanvas.value.context.camera.value
  )

  // Distortion pass
  distortPass.value = new ShaderPass(LensDistortionShader)
  distortPass.value.material.defines.CHROMA_SAMPLES = 10

  // compopser
  composer.value = new EffectComposer(tresCanvas.value.context.renderer.value)
  composer.value.setSize(window.innerWidth, window.innerHeight)
  composer.value.setPixelRatio(window.devicePixelRatio * 1.3)

  // output pass
  const outputPass = new OutputPass()

  // add the passes
  composer.value.addPass(renderPass)
  composer.value.addPass(distortPass.value)
  composer.value.addPass(outputPass)
}

onMounted(() => {
  isSceneReady()
  mountBackground()
  addRenderPasses()
  addParticles()
  // console.log(tresCanvas.value.context)
})
</script>

<style lang="scss" scoped>
.webgl-module-component {
  @apply w-screen max-w-full h-screen fixed z-1;
  background: #020919;
}
</style>

// use this only for FPS debugging
<style>
.tp-rotv {
  position: fixed !important;
}
</style>
