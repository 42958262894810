<template>
  <div class="site-loading-wrap">
    <div class="site-loading">
      <div>
        <img
          src="@/assets/img/loader-init.svg"
          alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import LottieWeb from 'lottie-web'
import loaderJson from '@/assets/lottie/loader.json'
import { ref, onMounted, onBeforeUnmount } from 'vue'

defineProps(['data'])

const lottie = ref(null)

onMounted(() => {
  LottieWeb.loadAnimation({
    container: lottie.value,
    renderer: 'svg',
    autoplay: true,
    loop: true,
    initialSegment: [245],
    speed: 2,
    animationData: loaderJson,
  })
})

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss">
.site-loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-image: url('@/assets/img/bg-home.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $blue;

  .site-loading {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
