import { ref, watch } from 'vue'

const modulesReady = ref([])
const modulesLoading = ref(false)
const loadedModules = ref([])

export default function useModulesReady() {
  const addPendingModule = (module) => {
    // console.log('Adding Pending Module: ', module)
    const normalized = module.toLowerCase()
    if (loadedModules.value.find((m) => m === normalized)) {
      clearIfEmpty()
      return
    }
    modulesLoading.value = true
    modulesReady.value = [normalized, ...modulesReady.value]
  }

  const removePendingModule = (module) => {
    // console.log('Removing Pending Module: ', module)
    const normalized = module.toLowerCase()
    if (modulesReady.value.find((m) => m === normalized)) {
      modulesReady.value = modulesReady.value.filter((m) => m !== normalized)
      loadedModules.value = [...loadedModules.value, normalized]
    }
    clearIfEmpty()
  }

  const clearIfEmpty = () => {
    if (modulesReady.value.length === 0) {
      modulesLoading.value = false
    }
  }

  // onMounted(() => {
  //   setTimeout(() => {
  //     clearIfEmpty()
  //   }, 1500)
  // })

  watch(modulesReady, () => {
    // console.log(modulesReady.value)
    // console.log('modules already loaded: ', loadedModules.value)
    if (modulesReady.value.length === 0) {
      modulesLoading.value = false
    } else {
      modulesLoading.value = true
    }
  })

  return {
    modulesLoading,
    addPendingModule,
    removePendingModule,
  }
}
