<template>
  <div class="responsive-image">
    <template v-if="image">
      <template v-if="props.size">
        <img
          v-if="
            props.size === 'thumbnail' && image.media_details.sizes.thumbnail
          "
          v-lazy="image.media_details.sizes.thumbnail.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :width="
            image.media_details.sizes.thumbnail
              ? image.media_details.sizes.thumbnail.width
              : image.media_details.width
          "
          :height="
            image.media_details.sizes.thumbnail
              ? image.media_details.sizes.thumbnail.height
              : image.media_details.height
          " />
        <img
          v-else-if="
            props.size === 'medium' && image.media_details.sizes.medium
          "
          v-lazy="image.media_details.sizes.medium.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :width="
            image.media_details.sizes.medium
              ? image.media_details.sizes.medium.width
              : image.media_details.width
          "
          :height="
            image.media_details.sizes.medium
              ? image.media_details.sizes.medium.height
              : image.media_details.height
          " />
        <img
          v-else-if="props.size === 'large' && image.media_details.sizes.large"
          v-lazy="image.media_details.sizes.large.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :width="
            image.media_details.sizes.large
              ? image.media_details.sizes.large.width
              : image.media_details.width
          "
          :height="
            image.media_details.sizes.large
              ? image.media_details.sizes.large.height
              : image.media_details.height
          " />
        <img
          v-else
          v-lazy="image.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :data-srcset="`
        ${
          image.media_details.sizes.medium_large
            ? image.media_details.sizes.medium_large.source_url
            : image.source_url
        } 450w,
                    ${
                      image.media_details.sizes.large
                        ? image.media_details.sizes.large.source_url
                        : image.source_url
                    } 1024w`"
          :width="image.media_details.width"
          :height="image.media_details.height" />
      </template>
      <template v-else>
        <img
          v-lazy="image.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :data-srcset="`
          ${
            image.media_details.sizes.medium_large
              ? image.media_details.sizes.medium_large.source_url
              : image.source_url
          } 450w,
          ${
            image.media_details.sizes.large
              ? image.media_details.sizes.large.source_url
              : image.source_url
          } 1024w`"
          :width="image.media_details.width"
          :height="image.media_details.height" />
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from '../../stores/main'

const store = useStore()
const props = defineProps({
  mediaId: {
    type: Number,
    required: true,
  },
  sizes: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    required: false,
  },
})

const request = ref({
  type: 'media',
  params: {
    _fields: 'id,link,media_details,alt_text,source_url',
  },
  id: props.mediaId,
  batch: true,
})

const image = computed(() => {
  return store.singleById(request.value)
})

const getMedia = () => {
  if (props.mediaId) {
    store.getSingleById(request.value)
  }
}

onBeforeMount(() => getMedia())
</script>

<style lang="scss">
.responsive-image {
  img {
    opacity: 0;
    filter: blur(5px);
    transition: all 0.3s ease;
  }

  img[lazy='loading'] {
    opacity: 0;
    filter: blur(5px);
    transition: all 0.3s ease;
  }
  img[lazy='error'] {
  }
  img[lazy='loaded'] {
    opacity: 1;
    filter: blur(0px);
    max-width: 100%;
    height: auto;
  }
}
</style>
