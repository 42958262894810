import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import VueLazyLoad from 'vue3-lazyload'
import VueBodyClass from 'vue-body-class'
import { store } from './pinia'
import VueGtag from 'vue-gtag'

export const Application = createApp(App)
const routes = router.getRoutes()
const vueBodyClass = new VueBodyClass(routes)

Application.use(router)
  .use(
    VueGtag,
    {
      config: {
        id: 'G-KF9WD28V1M',
      },
    },
    router
  )
  .use(VueLazyLoad, {
    adapter: {
      loaded({ el }) {
        setTimeout(() => el.classList.add('loaded'), 100)
      },
    },
  })
  .use(store)
  .mount('#app')
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})
