<template>
  <section class="hero">
    <div class="hero-container container x-large">
      <div class="hero-bg-container">
        <div
          ref="bg"
          class="hero-bg">
          <picture>
            <source
              media="(max-width: 1024px)"
              :srcset="
                data.hero_image.sizes.large
                  ? data.hero_image.sizes.large
                  : data.hero_image.url
              " />
            <img
              :src="data.hero_image.url"
              :alt="data.hero_image.alt"
              :title="data.hero_image.alt" />
          </picture>
        </div>
      </div>
      <div class="container">
        <div class="hero-content">
          <div class="hero-content-wrapper">
            <div class="headline h2">
              <h1
                ref="headline"
                v-html="data.hero_headline"></h1>
            </div>
            <div
              class="text editor-formatting font-larger"
              v-html="data.hero_text"></div>
            <div class="cta-container">
              <a
                v-if="data.hero_cta_1"
                :href="data.hero_cta_1.url"
                :target="data.hero_cta_1.target"
                class="cta"
                v-html="data.hero_cta_1.title">
              </a>
              <a
                v-if="data.hero_cta_2"
                :href="data.hero_cta_2.url"
                :target="data.hero_cta_2.target"
                class="cta"
                v-html="data.hero_cta_2.title">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow"></div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HeroComponent",
    "CLASSNAME": "hero",
    "URL": "http://insitro.local/test/"
   }
  #ENDMETA */
import gsap from 'gsap'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import UseIntroShown from '@/components/composables/useIntroShown.js'
import useModulesReady from '@/components/composables/useModulesReady'

const { modulesLoading } = useModulesReady()
defineProps(['data'])

const headline = ref(null)
const bg = ref(null)

const { introShown } = UseIntroShown()

const tl = gsap.timeline({
  paused: true,
  delay: 0.5,
})

const hasIntroShown = () => {
  if (!modulesLoading.value && introShown.value) {
    tl.play()
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 100)
  }
}

const animation = () => {
  tl.staggerTo(
    headline.value.children,
    0.75,
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 110%, 0 110%)',
      y: 0,
      delay: 0.15,
    },
    0.15
  ).to(
    bg.value,
    1.25,
    {
      css: { scale: 1, opacity: 0.3 },
    },
    0
  )
}

onMounted(() => {
  animation()
  hasIntroShown()
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.hero {
  width: 100%;
  padding: 180px 0 100px;

  @include tablet-landscape {
    padding: 150px 0 80px;
  }

  @include mobile {
    padding: 130px 0 60px;
  }

  .hero-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 720px;

    @media only screen and (max-width: $design-width) {
      height: 688px;
    }

    @include tablet-landscape {
      height: 600px;
    }

    @include mobile {
      height: 500px;
      padding: 40px 0;
    }

    .hero-bg-container {
      overflow: hidden;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 59px;
      z-index: -1;
    }

    .hero-bg {
      position: relative;
      width: 100%;
      height: 100%;
      transform: scale(1.1);
      opacity: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .hero-content {
    .hero-content-wrapper {
      position: relative;
    }

    .headline {
      h1 {
        line-height: 1.1;

        > * {
          clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
          transform: translateY(100%);
        }
      }
    }

    .text {
      max-width: 600px;
      margin: 20px 0 0;
    }

    .cta-container {
      display: flex;
      margin: 20px 0 0;

      a {
        margin-right: 30px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transform: translate(-50%, 50%) rotate(125deg);
    background-color: $yellow;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 11px;
      transform: translate(-50%, -50%);
      background-image: url('@/assets/img/arrow-out.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
</style>
