<template>
  <main class="single-leadership">
    <div class="back-container">
      <a
        href="/people/#leadership"
        class="btn-back">
        <img
          class="arrow"
          src="@/assets/img/arrow-back.svg"
          alt="Back Arrow" />
        <p>Back</p>
      </a>
    </div>
    <div class="container">
      <div
        v-if="member"
        class="member-photo-info">
        <div
          class="member-info-container"
          :class="{ 'no-facts': !member.acf.leadership_facts }">
          <div class="member-info">
            <div class="member-info-sections">
              <div class="member-info-section">
                <div class="name-title">
                  <div class="member-name">
                    <h1
                      class="h3"
                      v-html="member.title.rendered"></h1>
                  </div>
                  <div
                    v-if="member.acf.leadership_title"
                    class="member-title"
                    v-html="member.acf.leadership_title"></div>
                </div>
              </div>
              <div
                v-for="(item, index) in member.acf.leadership_facts"
                :key="index"
                class="member-info-section">
                <div
                  class="section-title font-larger"
                  v-html="item.leadership_fact_title"></div>
                <div
                  class="section-content editor-formatting"
                  v-html="item.leadership_fact_text"></div>
              </div>
            </div>
            <div
              v-if="
                member.acf.leadership_linkedin || member.acf.leadership_github
              "
              class="socials">
              <a
                v-if="member.acf.leadership_linkedin"
                :href="member.acf.leadership_linkedin"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-linkedin.svg"
                  alt="LinkedIn Logo" />
              </a>
              <a
                v-if="member.acf.leadership_github"
                :href="member.acf.leadership_github"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-google-scholar.svg"
                  alt="Google Scholar Logo" />
              </a>
            </div>
          </div>
        </div>
        <div class="member-photo">
          <ResponsiveImage
            v-if="member.featured_media"
            :native="true"
            :media-id="member.featured_media" />
        </div>
      </div>
      <div
        v-if="member && member.content"
        class="member-bio editor-formatting"
        v-html="member.content.rendered"></div>
      <div
        v-if="leadership && false"
        class="leadership">
        <div class="leadership-headline h3">The insitro Leadership</div>
        <div class="team-container">
          <template
            v-for="leader in leadership"
            :key="leader.id">
            <Leadership
              v-if="leader.id != member.id"
              :data="leader" />
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
// import pageview from 'vue-gtag'
import useUtils from '@/components/composables/useUtils.js'
import Leadership from '@/components/template-parts/Leadership.vue'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const { decodeTitle } = useUtils()

const request = ref({
  type: 'leadership',
  slug: props.slug,
  showLoading: true,
})

const leadershipRequest = {
  type: 'leadership',
  params: {
    _fields:
      'id,slug,link,title,featured_media,excerpt,acf,content,better_featured_image,team_categories',
  },
  showLoading: false,
}

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const leadership = computed(() => {
  return store.requestedItems(leadershipRequest)
})

const getMember = async () => {
  await store.getSingleBySlug(request.value)
  if (member.value) {
    store.updateDocTitle({
      parts: [decodeTitle(member.value.title.rendered), store.site.name],
    })
  } else {
    router.replace('/404')
  }
}

const getLeadership = () => {
  store.getItems(leadershipRequest)
}

onBeforeMount(async () => {
  await getMember()
  getLeadership()
})
</script>

<style lang="scss">
.single-leadership {
  padding: 170px 0 100px;

  .back-container {
    display: flex;
    padding: 0 39px 40px;

    @include mobile {
      padding: 0 20px 40px;
    }

    .btn-back {
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      height: 50px;
      border-radius: 300px;
      padding-right: 20px;
      background-color: $yellow;
      color: $blue;

      .arrow {
        margin-left: -13px;
      }
    }
  }

  .member-photo-info {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }
  }

  .member-info-container {
    width: 48%;
    border-radius: 3px;
    overflow: hidden;

    @include mobile {
      order: 1;
      width: 100%;
    }

    &.no-facts {
      .member-info {
        justify-content: center;

        .member-info-section {
          margin: 0;
        }
      }
    }

    .member-info {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 50px 40px;
      border: solid;
      border-image-slice: 1;
      border-width: 4px;
      border-image-source: linear-gradient(
        90deg,
        rgba(0, 217, 234, 1) 0%,
        rgba(255, 239, 0, 1) 100%
      );

      .member-info-section {
        margin: 0 0 30px;

        .member-title {
          margin: 10px 0 0;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          margin: 25px 0 0;
          background: linear-gradient(
            90deg,
            rgba(255, 239, 0, 1) 0%,
            rgba(0, 217, 234, 1) 100%
          );
        }

        .section-title {
          @include font-main-med;
        }

        .section-content {
          margin: 10px 0 0;
        }
      }

      .socials {
        display: flex;
        justify-content: flex-end;
        margin: auto 0 0;

        a {
          display: block;
          width: 37px;
          margin-right: 10px;

          &:last-child {
            margin: 0;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .member-photo {
    width: 48%;

    @include mobile {
      order: 0;
      width: 100%;
      margin: 0 0 20px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .member-bio {
    max-width: 766px;
    margin: 40px auto 0;
  }

  .leadership {
    margin: 100px 0 0;

    .leadership-headline {
      padding: 0 0 40px;
      margin: 0 0 20px;
      border-bottom: 1px solid $white;
    }

    .team-container {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 0;

      .team-member {
        width: 19.2%;
        margin-right: 1%;
        margin-bottom: 25px;

        @include tablet-landscape {
          width: 32.66%;
        }

        &:nth-child(5n) {
          margin-right: 0;

          @include tablet-landscape {
            margin-right: 1%;
          }
        }

        &:nth-child(3n) {
          @include tablet-landscape {
            margin-right: 0;
          }
        }

        @include mobile {
          width: 100% !important;
          margin: 0 0 20px !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}
</style>
