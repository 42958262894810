import { defineStore } from 'pinia'
import {
  fetchItems,
  fetchSingle,
  fetchSingleById,
  fetchSearch,
  fetchAcfOptions,
} from '@/api'

const { state } = window.__VUE_WORDPRESS__
const finalState = Object.assign(state, { cardIndex: 0 })

export const useStore = defineStore('main', {
  state: () => finalState,
  getters: {
    menu:
      (state) =>
      ({ name }) => {
        return state.menus[name]
      },
    request:
      (state) =>
      ({ type, params }) => {
        return state[type].requests.find((req) => {
          if (Object.keys(req.params).length === Object.keys(params).length) {
            return Object.keys(req.params).every(
              (key) => req.params[key] === params[key]
            )
          }
        })
      },
    getCurrentSingleBySlug:
      (state) =>
      ({ type, slug }) => {
        for (const id in state[type]) {
          if (decodeURI(state[type][id].slug) === slug) {
            return state[type][id]
          }
        }
      },
    getPosts:
      (state) =>
      ({ type }) => {
        return state[type]
      },
    getTotalPages:
      (state) =>
      ({ type, params }) => {
        const request = state.request({ type, params })
        return request ? request.totalPages : 0
      },
    requestedItems:
      (state) =>
      ({ type, params }) => {
        const request = state.request({ type, params })
        return request ? request.data.map((id) => state[type][id]) : []
      },
    singleBySlug:
      (state) =>
      ({ type, slug }) => {
        for (const id in state[type]) {
          if (decodeURI(state[type][id].slug) === slug) {
            return state[type][id]
          }
        }
      },
    singleById:
      (state) =>
      ({ type, id }) => {
        return state[type][id]
      },
    searchTerm: (state) => () => {
      return state.search_term
    },
    acfOptions: (state) => () => {
      return state.acf_options
    },
  },
  actions: {
    addItem({ type, item }) {
      if (item && type && !this[type].hasOwnProperty(item.id)) {
        // eslint-disable-line
        this[type][item.id] = item
      }
    },
    addRequest({ type, request }) {
      this[type].requests.push(request)
    },
    setLoading(loading) {
      if (loading === false) {
        setTimeout(() => {
          this.site.loading = loading
        }, 500)
      } else {
        this.site.loading = loading
      }
    },
    setIntro(introShown) {
      this.site.introShown = introShown
    },
    setDocTitle(title) {
      this.site.docTitle = title
    },
    setSearchTerm(searchTerm) {
      this.search_term = searchTerm
    },
    setAcfOptions(options) {
      this.acf_options = options
    },
    getSingleBySlug({ type, slug, showLoading = false }) {
      if (!this.getCurrentSingleBySlug({ type, slug })) {
        if (showLoading) {
          this.setLoading(true)
        }
        return fetchSingle({ type, params: { slug } }).then(
          ({ data: [item] }) => {
            this.addItem({ type, item })
            if (showLoading) {
              this.setLoading(false)
            }
            return item
          }
        )
      } else {
        // simulated loading
        // this.setLoading(true)
        // return new Promise((resolve) => {
        //   setTimeout(() => {
        //     this.setLoading(false)
        //     resolve()
        //   }, 100)
        // })
        return Promise.resolve()
      }
    },
    getSingleById({
      type,
      id,
      params,
      embed = false,
      showLoading = false,
      batch = false,
    }) {
      if (!this.singleById({ type, id, params })) {
        if (showLoading) {
          this.setLoading(true)
        }
        return fetchSingleById({ type, id, params, batch, embed }).then(
          ({ data }) => {
            if (batch) {
              data.forEach((item) => this.addItem({ type, item }))
            } else {
              this.addItem({ type, item: data })
            }
            if (showLoading) {
              this.setLoading(false)
            }
          }
        )
      } else {
        return Promise.resolve()
      }
    },
    getItems({ type, params, showLoading = false }) {
      if (!this.request({ type, params })) {
        if (showLoading) {
          this.setLoading(true)
        }
        return fetchItems({ type, params }).then(
          ({
            data: items,
            headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages },
          }) => {
            items.forEach((item) => this.addItem({ type, item }))
            this.addRequest({
              type,
              request: {
                params,
                total: parseInt(total),
                totalPages: parseInt(totalPages),
                data: items.map((i) => i.id),
              },
            })
            if (showLoading) {
              this.setLoading(false)
            }
          }
        )
      } else {
        return Promise.resolve()
      }
    },
    updateDocTitle({ parts = [], sep = ' – ' }) {
      parts[0]
        ? this.setDocTitle(unescape(parts.join(sep)))
        : this.setDocTitle(unescape(parts[1]))
      document.title = this.site.docTitle
    },
    getAcfOptions() {
      return fetchAcfOptions().then((response) => {
        return response.data.acf
      })
    },
    performSearch({ term, params }) {
      return fetchSearch({ term, params })
        .then((response) => {
          return response
        })
        .catch((err) => {
          console.error({ err: err }, `No results for ${term}.`)
          throw err
        })
    },
  },
})
