<template>
  <section class="homepage-hero">
    <div class="container">
      <div class="hero-content">
        <div class="headline h1">
          <h1
            ref="headline"
            v-html="data.homepage_hero_headline"></h1>
        </div>
        <div
          class="text editor-formatting font-larger"
          v-html="data.homepage_hero_text"></div>
        <a
          v-if="data.homepage_hero_cta"
          :href="data.homepage_hero_cta.url"
          :target="data.homepage_hero_cta.target"
          class="btn"
          v-html="data.homepage_hero_cta.title"></a>
      </div>
    </div>
    <div class="scroll-down">
      <p>Scroll for More</p>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HomepageHeroComponent",
    "CLASSNAME": "homepage-hero",
    "URL": "http://insitro.local/test/"
   }
  #ENDMETA */
import gsap from 'gsap'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import useModulesReady from '@/components/composables/useModulesReady'

const { modulesLoading } = useModulesReady()

defineProps(['data'])

const headline = ref(null)

const tl = gsap.timeline({
  paused: true,
  delay: 0.5,
})

const animation = () => {
  if (modulesLoading.value === true) {
    setTimeout(animation, 100)
  } else {
    tl.staggerTo(
      headline.value.children,
      0.75,
      {
        clipPath: 'polygon(0 0, 100% 0, 100% 110%, 0 110%)',
        y: 0,
      },
      0.15
    )
  }
}

onMounted(() => {
  animation()
  tl.play()
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.homepage-hero {
  position: relative;
  padding: 300px 0 220px;

  @include tablet-landscape {
    padding: 200px 0 100px;
  }

  @include mobile {
    height: auto;
    padding: 140px 0 100px;
  }

  .hero-content {
    max-width: 610px;

    .headline {
      h1 {
        line-height: 1.1;

        > * {
          clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
          transform: translateY(100%);
        }
      }
    }

    .text {
      margin: 20px 0 0;
    }

    .btn {
      margin: 40px 0 0;
    }
  }

  .scroll-down {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 1.6rem;
    color: $tech-blue;

    @include mobile {
      bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      width: 13px;
      height: 22px;
      margin: 10px auto 0;
      background-image: url('@/assets/img/arrow-down-blue.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
</style>
