<template>
  <footer
    v-show="!store.site.loading && site"
    ref="trigger"
    class="site-footer">
    <div class="footer-head">
      <div class="logo">
        <router-link
          :to="'/'"
          title="Home">
          <img
            ref="logo"
            :src="site.site_logo.url"
            alt="insitro Logo" />
        </router-link>
      </div>
      <div class="menu">
        <footer-menu name="footer-menu" />
        <a href="mailto:info@insitro.com">info@insitro.com</a>
      </div>
    </div>
    <div
      v-if="site.footer_address"
      class="footer-address">
      <address v-html="site.footer_address"></address>
    </div>
    <div class="footer-nav">
      <div class="socials">
        <a
          v-if="site.linkedin"
          :href="site.linkedin"
          target="_blank"
          class="social linkedin">
          <img
            src="@/assets/img/logo-linkedin.svg"
            alt="LinkedIn Logo" />
        </a>
        <a
          v-if="site.twitter"
          :href="site.twitter"
          target="_blank"
          class="social X">
          <img
            src="@/assets/img/logo-x.svg"
            alt="X Logo" />
        </a>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="copyright">
        <p>© {{ currentYear }} insitro. All Rights Reserved.</p>
      </div>
      <footer-menu name="legal-menu" />
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const site = ref(store.acfOptions())

const trigger = ref(null)
const logo = ref(null)

const scrollPos = ref(0)

let tl

const currentYear = computed(() => {
  return new Date().getFullYear()
})

const refreshScrollPositions = () => {
  if (scrollPos.value != window.pageYOffset) {
    scrollPos.value = window.pageYOffset
    ScrollTrigger.refresh()
  }
}

onMounted(() => {
  setInterval(refreshScrollPositions, 3000)

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top bottom',
      end: 'bottom-=150 bottom',
      scrub: true,
    },
  })

  tl.to(logo.value, {
    y: 0,
  })
})

onBeforeUnmount(() => {
  clearInterval(refreshScrollPositions)
  tl.kill()
})
</script>

<style lang="scss">
.site-footer {
  z-index: 2;
  width: 95%;
  max-width: 1380px;
  padding: 40px;
  margin: 0 auto 28px;
  color: $white;
  background-color: $blue;
  clip-path: polygon(0 0, 100% 0, 100% 80%, calc(90% - 40px) 100%, 0 100%);

  @include mobile {
    padding: 20px;
  }

  .footer-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    .logo {
      a {
        display: block;

        img {
          position: relative;
          width: 190px;
          transform: translateY(-250%);

          @include mobile {
            width: 120px;
          }
        }
      }
    }

    .menu {
      text-align: right;

      @include mobile {
        text-align: left;
        margin: 20px 0 0;
      }

      .nav-container {
        margin: 0 0 10px;

        @include mobile {
          margin: 0;
        }

        li {
          margin: 0 0 8px;

          @include mobile {
            margin: 0;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .footer-address {
    font-size: 1.8rem;
    line-height: 1;

    @include mobile {
      margin: 30px 0 0;
    }
  }

  .footer-nav {
    width: 90%;
    max-width: 1168px;
    padding: 0 0 35px;
    margin: 80px 0 0;
    font-size: 2.2rem;
    border-bottom: 1px solid $white;

    @include mobile {
      margin: 40px 0 0;
    }

    .socials {
      display: flex;

      a {
        display: block;
        margin-right: 10px;

        &:last-child {
          margin: 0;
        }

        img {
          width: 44px;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    margin: 20px 0 0;
    font-size: 1.4rem;

    @include mobile {
      flex-direction: column;
    }

    .copyright {
      color: $yellow;

      @include mobile {
        order: 1;
      }
    }

    .nav-container {
      display: flex;
      margin-left: 60px;

      @include mobile {
        order: 0;
        margin: 0 0 20px;
      }

      li {
        margin-right: 60px;

        &:last-child {
          margin: 0;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
