<template>
  <section
    class="site-background"
    :class="bgState.class">
    <template v-if="bgState.isHomepage && device != 'mobile'">
      <Suspense>
        <DNAMesh v-show="bgState.isHomepage" />
      </Suspense>
    </template>
  </section>
</template>

<script setup>
import DNAMesh from '@/components/utility/DNAMesh.vue'
import useUtils from '@/components/composables/useUtils.js'
import { inject } from 'vue'

const { device } = useUtils()
const bgState = inject('bgState')
</script>

<style lang="scss">
.site-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/img/bg-home.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-color: $blue;

  @include mobile {
    background-size: cover;
  }

  &.light {
    background-image: url('@/assets/img/bg-light.jpg');
  }
}
</style>
