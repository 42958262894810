import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from '../scripts/emitter'
import { useStore } from '@/stores/main'
import locoScroll from '../assets/js/LenisScroll'

const { url } = window.__VUE_WORDPRESS__.routing
// scroll position is handled in @after-leave transition hook
if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // const position = { left: 0, top: 0 }
    // Only way I could see to go to an anchor on another page. Possible race condition.
    if (to.hash) {
      if (from.path === to.path) {
        locoScroll.value.scrollTo(to.hash, {
          immediate: false,
          offset: -90,
        })
      }
      const navigate = () => {
        const store = useStore()
        if (store.site.loading) {
          setTimeout(() => {
            navigate()
          }, 100)
        } else {
          emitter.once('safeToScroll', () => {
            setTimeout(() => {
              locoScroll.value.scrollTo(to.hash, {
                immediate: true,
                offset: -90,
              })
            }, 500)
          })
        }
      }
      navigate()
    }

    if (savedPosition) {
      return new Promise((resolve) => {
        emitter.once('scrollBeforeEnter', (done) => {
          if (savedPosition.top > 0) {
            emitter.once('safeToScroll', () => {
              locoScroll.value.scrollTo(savedPosition.top, {
                immediate: true,
              })
            })
          } else {
            emitter.once('safeToScroll', () => {
              locoScroll.value.scrollTo('top', {
                immediate: true,
              })
            })
          }
          done()
          resolve()
        })
      })
    } else {
      return new Promise((resolve) => {
        emitter.on('scrollBeforeEnter', (done) => {
          locoScroll.value.scrollTo('top', {
            immediate: true,
          })
          done()
          resolve()
        })
      })
    }
  },
})
