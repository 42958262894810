<template>
  <main class="single-team">
    <div class="back-container">
      <a
        class="btn-back"
        @click="goBack()">
        <img
          class="arrow"
          src="@/assets/img/arrow-back.svg"
          alt="Back Arrow" />
        <p>Back</p>
      </a>
    </div>
    <div class="container">
      <div
        v-if="member"
        class="member-photo-info-container">
        <div class="member-photo-info">
          <div class="member-info">
            <div class="member-info-section">
              <div class="name-title">
                <div class="member-name">
                  <h1
                    class="h3"
                    v-html="member.title.rendered"></h1>
                </div>
                <div
                  v-if="member.acf.team_member_title"
                  class="member-title"
                  v-html="member.acf.team_member_title"></div>
              </div>
            </div>
            <div
              v-if="member.acf.team_member_hometown"
              class="member-info-section">
              <div class="section-title font-larger">Hometown:</div>
              <div
                class="section-content editor-formatting"
                v-html="member.acf.team_member_hometown"></div>
            </div>
            <div
              v-if="member.acf.team_member_edu"
              class="member-info-section">
              <div class="section-title font-larger">Education:</div>
              <div
                class="section-content editor-formatting"
                v-html="member.acf.team_member_edu"></div>
            </div>
            <div
              v-if="member.acf.team_member_prev_exp"
              class="member-info-section">
              <div class="section-title font-larger">Previous Experience:</div>
              <div
                class="section-content editor-formatting"
                v-html="member.acf.team_member_prev_exp"></div>
            </div>
            <div
              v-if="member.acf.team_member_responsibilities"
              class="member-info-section">
              <div class="section-title font-larger">
                Responsibilities at insitro:
              </div>
              <div
                class="section-content editor-formatting"
                v-html="member.acf.team_member_responsibilities"></div>
            </div>
            <div
              v-if="member.acf.team_member_other"
              class="member-info-section">
              <div class="section-title font-larger">
                Other insitrocytes Describe Me As:
              </div>
              <div
                class="section-content editor-formatting"
                v-html="member.acf.team_member_other"></div>
            </div>
            <div class="socials mobile">
              <a
                v-if="member.acf.team_member_linkedin"
                :href="member.acf.team_member_linkedin"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-linkedin.svg"
                  alt="LinkedIn Logo" />
              </a>
              <a
                v-if="member.acf.team_member_google_scholar"
                :href="member.acf.team_member_google_scholar"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-google-scholar.svg"
                  alt="Google Scholar Logo" />
              </a>
              <a
                v-if="member.acf.team_member_github"
                :href="member.acf.team_member_github"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-github.svg"
                  alt="Github Logo" />
              </a>
            </div>
          </div>
          <div class="member-photo">
            <ResponsiveImage
              v-if="member.featured_media"
              :native="true"
              :media-id="member.featured_media" />
            <div class="socials desktop">
              <a
                v-if="member.acf.team_member_linkedin"
                :href="member.acf.team_member_linkedin"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-linkedin.svg"
                  alt="LinkedIn Logo" />
              </a>
              <a
                v-if="member.acf.team_member_google_scholar"
                :href="member.acf.team_member_google_scholar"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-google-scholar.svg"
                  alt="Google Scholar Logo" />
              </a>
              <a
                v-if="member.acf.team_member_github"
                :href="member.acf.team_member_github"
                target="_blank"
                class="no-router">
                <img
                  src="@/assets/img/logo-github.svg"
                  alt="Github Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="member && member.content"
        class="member-bio editor-formatting"
        v-html="member.content.rendered"></div>
    </div>
  </main>
</template>

<script setup>
import { computed, ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
// import pageview from 'vue-gtag'
import useUtils from '@/components/composables/useUtils.js'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const goBack = () => {
  if (window.history.length <= 2) router.push('/people/#team')
  else router.go(-1)
}

const { decodeTitle /*convertToRelative */ } = useUtils()

const request = ref({
  type: 'team',
  slug: props.slug,
  showLoading: true,
})

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const getMember = async () => {
  await store.getSingleBySlug(request.value)
  if (member.value) {
    store.updateDocTitle({
      parts: [decodeTitle(member.value.title.rendered), store.site.name],
    })
    // .then(() => {
    // pageview({
    //   page_path: convertToRelative(member.value.link),
    //   page_title: document.title,
    //   page_location: window.location.href,
    // })
    // })
  } else {
    router.replace('/404')
    // pageview({
    //   page_path: '/404',
    //   page_title: document.title,
    //   page_location: window.location.href,
    // })
  }
}

onBeforeMount(async () => {
  await getMember()
})
</script>

<style lang="scss">
.single-team {
  padding: 170px 0 100px;

  .back-container {
    display: flex;
    padding: 0 39px 40px;

    .btn-back {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      height: 50px;
      border-radius: 300px;
      padding-right: 20px;
      background-color: $yellow;
      color: $blue;

      .arrow {
        margin-left: -13px;
      }
    }
  }

  .member-photo-info-container {
    border-radius: 3px;
    overflow: hidden;
  }

  .member-photo-info {
    display: flex;
    padding: 50px 40px;
    border: solid;
    border-image-slice: 1;
    border-width: 4px;
    border-image-source: linear-gradient(
      90deg,
      rgba(0, 217, 234, 1) 0%,
      rgba(255, 239, 0, 1) 100%
    );

    @include mobile {
      flex-direction: column;
    }

    .member-info {
      flex: 1;
      margin-right: 60px;

      @include mobile {
        order: 2;
        margin: 30px 0 0;
      }

      .member-info-section {
        margin: 0 0 30px;

        &:last-child {
          margin: 0;

          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          margin: 25px 0 0;
          background: linear-gradient(
            90deg,
            rgba(255, 239, 0, 1) 0%,
            rgba(0, 217, 234, 1) 100%
          );
        }

        .section-title {
          @include font-main-med;
        }

        .section-content {
          margin: 10px 0 0;
        }
      }

      .member-title {
        margin: 10px 0 0;
      }
    }

    .member-photo {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include mobile {
        width: 100%;
      }

      .responsive-image {
        margin: 0 0 20px;
      }

      img {
        width: auto;
        object-fit: cover;
        margin-left: auto;

        @include mobile {
          margin: auto;
        }
      }
    }

    .socials {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;

      &.destkop {
        @include mobile {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @include mobile {
          display: flex;
        }
      }

      @include mobile {
        display: none;
      }

      a {
        display: block;
        width: 37px;
        margin-right: 10px;

        &:last-child {
          margin: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .member-bio {
    max-width: 766px;
    margin: 40px auto 0;
  }
}
</style>
