<template>
  <div class="modal value-chain-modal">
    <div
      class="modal-overlay"
      @click="closeModal"></div>
    <div class="modal-content">
      <div
        class="modal-close"
        @click="closeModal"></div>
      <div
        v-if="valueChainModal.isPyramid"
        class="pyr-layout"
        data-lenis-prevent
        :class="{
          'has-video': valueChainModal.data.items[valueChainModal.index].video,
        }">
        <div class="top-section">
          <span>{{
            valueChainModal.data.items[valueChainModal.index].section_title
          }}</span>
          <div class="mini-pyramid-wrapper">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 69 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.77339 32.0601L1.57458 40.7679C0.914791 41.6948 1.57741 42.9798 2.71511 42.9798H21.805C22.5782 42.9798 23.205 42.353 23.205 41.5798V31.9898C23.205 31.2166 22.5782 30.5898 21.805 30.5898H10.6247C9.49241 30.5898 8.43005 31.1376 7.77339 32.0601Z"
                fill="#BABBBB"
                class="sector"
                :class="{ 'active-sector': valueChainModal.index === 2 }" />
              <path
                d="M60.5118 32.0601L66.7106 40.7679C67.3704 41.6948 66.7077 42.9798 65.5701 42.9798H46.4802C45.707 42.9798 45.0802 42.353 45.0802 41.5798V31.9898C45.0802 31.2166 45.707 30.5898 46.4802 30.5898H57.6604C58.7927 30.5898 59.8551 31.1376 60.5118 32.0601Z"
                fill="#BABBBB"
                class="sector"
                :class="{ 'active-sector': valueChainModal.index === 4 }" />
              <rect
                x="24.7793"
                y="30.625"
                width="18.76"
                height="12.39"
                rx="1.4"
                fill="#BABBBB"
                class="sector"
                :class="{ 'active-sector': valueChainModal.index === 3 }" />
              <path
                d="M18.969 16.7098C19.631 15.8404 20.661 15.3301 21.7537 15.3301H46.5654C47.6581 15.3301 48.6881 15.8404 49.3501 16.7098L57.0878 26.872C57.7895 27.7936 57.1323 29.1201 55.9739 29.1201H12.3452C11.1868 29.1201 10.5296 27.7936 11.2313 26.872L18.969 16.7098Z"
                fill="#BABBBB"
                :class="{ 'active-sector': valueChainModal.index === 1 }" />
              <path
                d="M30.6822 1.27425C31.3471 0.467388 32.3378 0 33.3833 0H34.9349C35.9805 0 36.9712 0.46739 37.6361 1.27426L46.0619 11.4997C46.8144 12.413 46.1648 13.79 44.9815 13.79H23.3368C22.1535 13.79 21.5038 12.413 22.2564 11.4997L30.6822 1.27425Z"
                fill="#BABBBB"
                class="sector"
                :class="{ 'active-sector': valueChainModal.index === 0 }" />
            </svg>
          </div>
        </div>
        <div
          v-if="
            !valueChainModal.data.items[valueChainModal.index].isMp4 &&
            valueChainModal.data.items[valueChainModal.index].video
          "
          class="video-section">
          <iframe
            width="100%"
            height="100%"
            :src="`https://www.youtube.com/embed/${
              valueChainModal.data.items[valueChainModal.index].video
            }?rel=0&autoplay=1&cc_load_policy=1`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div
          v-else-if="
            valueChainModal.data.items[valueChainModal.index].isMp4 &&
            valueChainModal.data.items[valueChainModal.index].mp4_file
          "
          class="video-section">
          <video
            :key="`video_${valueChainModal.index}`"
            width="320"
            height="240"
            controls
            allowfullscreen
            autoplay>
            <source
              :src="
                valueChainModal.data.items[valueChainModal.index].mp4_file.url
              "
              type="video/mp4" />

            Your browser does not support the video tag.
          </video>
        </div>

        <div class="content">
          <h2
            v-if="
              valueChainModal.data.items[valueChainModal.index].title &&
              !valueChainModal.data.items[valueChainModal.index].video
            "
            class="content-title">
            {{ valueChainModal.data.items[valueChainModal.index].title }}
          </h2>
          <p
            v-if="valueChainModal.data.items[valueChainModal.index].sub"
            class="content-sub">
            {{ valueChainModal.data.items[valueChainModal.index].sub }}
          </p>
          <div
            v-if="valueChainModal.data.items[valueChainModal.index].content"
            class="content-copy"
            v-html="
              valueChainModal.data.items[valueChainModal.index].content
            " />
        </div>
      </div>
      <div
        v-else
        class="modal-content-wrapper"
        data-lenis-prevent>
        <div
          class="modal-line"
          :class="`pos-${index + 1}`"></div>
        <div
          class="headline h4"
          v-html="data[index].value_chain_hex_headline"></div>
        <div
          class="title"
          v-html="data[index].value_chain_hex_title"></div>
        <div
          class="text editor-formatting"
          v-html="data[index].value_chain_hex_text"></div>
      </div>
      <div class="arrows">
        <!-- <button
          class="arrow prev"
          :disabled="index == 0"
          @click="prev"></button>
        <button
          class="arrow next"
          :disabled="
            index ==
            (valueChainModal.isPyramid
              ? data.items.length - 1
              : data.length - 1)
          "
          @click="next"></button> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, inject } from 'vue'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

defineProps(['index', 'data'])

const valueChainModal = inject('valueChainModal')
const { locoScroll } = useSmoothScroll()

const closeModal = () => {
  valueChainModal.value.isPyramid = false
  valueChainModal.value.open = false
  valueChainModal.value.index = null
  valueChainModal.value.data = null
}

// const prev = () => {
//   valueChainModal.value.index = valueChainModal.value.index - 1
// }

// const next = () => {
//   valueChainModal.value.index = valueChainModal.value.index + 1
// }

const closeHandler = (e) => {
  if (e.keyCode === 27) {
    closeModal()
  }
}

onMounted(() => {
  document.body.classList.add('no-overflow')
  locoScroll.value.stop()
  document.addEventListener('keyup', closeHandler, { passive: true })
})

onBeforeUnmount(() => {
  document.body.classList.remove('no-overflow')
  locoScroll.value.start()
  document.removeEventListener('keyup', closeHandler, { passive: true })
})
</script>

<style lang="scss">
.value-chain-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: -18px;
    width: 94px;
    height: 94px;
    background-image: url('@/assets/img/icon-close.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-height: 600px) {
      width: 70px;
      height: 70px;
    }

    @include mobile {
      width: 70px;
      height: 70px;
    }
  }

  .modal-content {
    position: relative;
    width: 95%;
    max-width: 500px;
    padding: 94px 0 40px;
    z-index: 1;

    @media only screen and (max-height: 600px) {
      padding-top: 70px;
    }

    @include mobile {
      padding-top: 70px;
    }

    .modal-content-wrapper {
      position: relative;
      width: 100%;
      height: calc(100vh - 134px);
      max-height: 500px;
      padding: 100px 30px 80px;
      -webkit-overflow-scrolling: touch;
      overflow: auto;
      color: $machine-gray;
      background-color: $white;

      @media only screen and (max-height: 600px) {
        height: calc(100vh - 110px);
        padding: 60px 30px 40px;
      }

      @include mobile {
        height: calc(100vh - 110px);
        max-height: 400px;
        padding: 60px 30px 40px;
      }

      .modal-line {
        position: absolute;
        top: 30px;
        left: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        overflow: hidden;

        &.pos-1:before {
          transform: translateX(80px);
        }

        &.pos-2:before {
          transform: translateX(30px);
        }

        &.pos-3:before {
          transform: translateX(-30px);
        }

        &:before {
          content: '';
          display: block;
          width: 127px;
          height: 22px;
          background-image: url('@/assets/img/line-short.svg');
          background-size: contain;
          background-repeat: no-repeat;
          transform: translateX(0px);
          @include transition;
        }
      }

      .headline {
        max-width: 80%;
      }

      .title {
        margin: 30px 0 0;
        @include font-main-semibold;
      }

      .text {
        margin: 20px 0 0;
      }
    }

    .arrows {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      transform: translateY(calc(-50% + 50px));

      @include tablet-landscape(1) {
        top: initial;
        bottom: 0;
        right: 0;
        transform: translate(0);
      }

      .arrow {
        cursor: pointer;
        width: 94px;
        height: 94px;
        padding: 0;
        opacity: 1;
        background-image: url('@/assets/img/icon-arrow.svg');
        background-repeat: no-repeat;
        background-size: contain !important;
        background-color: transparent !important;
        border: none !important;
        @include transition;

        @include mobile {
          width: 60px;
          height: 60px;
        }

        &.prev {
          transform: rotate(180deg);
        }

        &:disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.7;
        }
      }
    }
  }
}
.pyr-layout {
  @apply overflow-y-auto h-[540px] text-[#353841] relative bg-white rounded-[4px];
  box-shadow: 0px 40px 40px 20px rgba(1, 11, 28, 0.2);
  .top-section {
    @apply p-[25px] flex justify-between items-center;
    span {
      @apply font-bold;
    }
    .mini-pyramid-wrapper {
      @apply h-[52px] max-w-[80px];
      svg {
        .sector {
          transition: all 0.2s;
        }
        .active-sector {
          fill: #1d78da;
        }
      }
    }
  }
  .video-section {
    @apply h-[281px] w-full  bg-black;
    video {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
  .content {
    @apply my-[20px] px-[34px];
    &-title {
      @apply max-w-[323px] mb-[25px] text-[32px] leading-[36px];
    }
    &-sub {
      @apply mb-[10px] text-[18px] not-italic font-medium leading-[22px] tracking-[-0.36px];
    }
  }
  &.has-video {
    @apply lg:h-[600px];
    .content {
      @apply mt-[30px] lg:mt-[70px];
    }
  }
}
</style>
