<template>
  <header
    id="header-nav"
    ref="header"
    class="header-nav"
    :class="`${navOpen ? 'nav-open' : ''} ${direction} ${
      scrolling ? 'scrolling' : ''
    }`">
    <nav ref="navWrapper">
      <div class="logo">
        <a href="/">
          <img
            :src="site.site_logo.url"
            alt="insitro logo" />
        </a>
      </div>
      <div
        class="nav-wrapper"
        :class="`${navOpen ? 'nav-open' : ''} `"
        data-lenis-prevent>
        <ul
          ref="nav"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in topLevelMenu(menu)"
              :key="item.id"
              appear
              @before-enter="beforeEnter"
              @enter="enter">
              <template v-if="item.parent === '0'">
                <NavMenuItem
                  :key="item.id"
                  :data-ind="index"
                  :menu="menu"
                  :item="item"
                  @click="close" />
              </template>
            </transition-group>
          </template>
        </ul>
        <router-link
          v-if="site.nav_cta"
          class="btn blue"
          :to="convertToRelative(site.nav_cta.url)"
          :target="site.nav_cta.target"
          @click="close">
          {{ site.nav_cta.title }}
        </router-link>
      </div>
      <div
        class="hamburger"
        :class="`${navOpen ? 'nav-open' : ''}`"
        @click="open">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  </header>
</template>

<script setup>
import NavMenuItem from './NavMenuItem.vue'
import gsap from 'gsap'
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/components/composables/useUtils.js'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref(null)
const navOpen = ref(false)
const direction = ref('up')
const scrollTop = ref(0)
const scrolling = ref(false)
const { convertToRelative } = useUtils()
const { introShown } = useIntroShown()

const { locoScroll } = useSmoothScroll()

const topLevelMenu = (menu) => {
  const topMenu = menu.filter((x) => x.parent === '0')
  return topMenu
}

const open = () => {
  if (navOpen.value) {
    navOpen.value = false
    locoScroll.value.start()
  } else {
    navOpen.value = true
    locoScroll.value.stop()
  }
}

const close = () => {
  navOpen.value = false
  locoScroll.value.start()
}

const beforeEnter = (el) => {
  // add the initial style to the elements
  el.style.transform = 'translateY(10px)'
  el.style.opacity = 0
}

const enter = (el) => {
  gsap.to(el, {
    y: 0,
    opacity: 1,
    duration: 0.3,
    delay: el.dataset.ind * 0.3,
  })
}

function handleScroll() {
  const scrollDir = locoScroll.value.direction

  if (scrollDir == -1 || scrollDir == 0) {
    direction.value = 'up'
  } else {
    direction.value = 'down'
  }

  scrollTop.value = window.pageYOffset
  if (scrollTop.value >= 100) {
    scrolling.value = true
  } else {
    direction.value = 'up'
    scrolling.value = false
  }
}

onMounted(() => {
  document.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  document.removeEventListener('scroll', handleScroll, false)
})
</script>

<style lang="scss">
.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid $white;
  z-index: 10;
  background: linear-gradient(
    180deg,
    rgba(2, 9, 25, 0.8) 0%,
    rgba(2, 9, 25, 0.15) 100%
  );
  transform: translateY(0%);
  @include transition;

  &.down {
    transform: translateY(-100%);
  }

  &.scrolling {
    background: linear-gradient(
      180deg,
      rgba(2, 9, 25, 0.8) 0%,
      rgba(2, 9, 25, 0.5) 100%
    );

    nav {
      padding: 20px 39px;

      @include tablet-landscape {
        padding: 20px;
      }
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 39px;
    @include transition;

    &.scrolling {
      padding: 20px 39px;
    }

    @include tablet-landscape {
      padding: 20px;
    }

    .logo {
      @include tablet-landscape(1) {
        z-index: 11;
      }

      a {
        display: block;

        img {
          width: 130px;

          @include tablet-landscape {
            width: 100px;
          }
        }
      }
    }

    .nav-wrapper {
      display: flex;

      &.nav-open {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        z-index: 10;
      }

      @include tablet-landscape(1) {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        padding: 80px 0 40px;
        overflow: auto;
        z-index: 0;
        background-color: $blue;
        @include transition;
      }

      .menu-main-menu-container {
        display: flex;
        align-items: center;
      }

      .nav-container {
        display: flex;
        align-items: center;
        margin-right: 60px;

        @media only screen and (max-width: 1100px) {
          margin-right: 0;
        }

        @include tablet-landscape(1) {
          margin: 0;
          flex-direction: column;
          margin: 0 0 50px;
        }

        li {
          margin-right: 10px;

          @include tablet-landscape(1) {
            margin: 0 0 20px;
          }

          &:last-child {
            margin: 0;
          }

          a {
            display: block;
            font-size: 1.8rem;
            border-radius: 300px;
            line-height: 1;
            border-radius: 500px;
            padding: 9px 24px;
            color: $white;
            border: 2px solid transparent;
            @include transition;

            &.router-link-exact-active,
            &:hover {
              border: 2px solid $tech-blue;
            }
          }
        }
      }

      .btn {
        @include mobile {
          width: 80%;
          margin: 0 auto;
        }

        &.router-link-exact-active {
          color: $white;

          &:before {
            background-color: transparent;
            border: 1px solid $tech-blue;
          }
        }
      }
    }

    .hamburger {
      position: relative;
      display: none;
      width: 33px;
      height: 26px;

      @include tablet-landscape(1) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 11;
      }

      &.nav-open {
        span {
          &:first-child,
          &:last-child {
            top: 50%;
            left: 50%;
          }

          &:first-child {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:nth-child(2) {
            transform: translateY(-50%) scale(0);
          }

          &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }

      span {
        position: absolute;
        width: 100%;
        height: 2px;
        display: block;
        background-color: $yellow;
        @include transition;

        &:first-child,
        &:last-child {
          left: 0;
        }

        &:first-child {
          top: 0;
          transform: translate(0%, 0%) rotate(0deg);
        }

        &:nth-child(2) {
          right: 0;
          top: 50%;
          width: 80%;
          transform: translateY(-50%) scale(1);
        }

        &:last-child {
          top: 100%;
          transform: translate(0%, 0%) rotate(0deg);
        }
      }
    }
  }
}
</style>
