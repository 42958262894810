<template>
  <div class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-if="page.template === 'page-legal.php'">
          <div class="page-legal-wrapper">
            <div class="container editor-formatting font-larger">
              <h1>{{ page.title.rendered }}</h1>
              <div
                class="legal-content"
                v-html="page.content.rendered"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="(module, index) in page.acf.modules">
            <template v-if="module.acf_fc_layout === 'HomepageHeroComponent'">
              <HomepageHeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'HeroComponent'">
              <HeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="
                module.acf_fc_layout === 'PageCalloutParallaxComponent'
              ">
              <PageCalloutParallaxComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'CultureComponent'">
              <CultureComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'FeaturedNewsComponent'">
              <FeaturedNewsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'CareersComponent'">
              <CareersComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'FeaturedTeamComponent'">
              <FeaturedTeamComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'HexagonExplainerComponent'">
              <HexagonExplainerComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PipelineComponent'">
              <PipelineComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'PageCalloutComponent'">
              <PageCalloutComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'PageCalloutHexComponent'">
              <PageCalloutHexComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'GeneralContentComponent'">
              <GeneralContentComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ImageComponent'">
              <ImageComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'VideoComponent'">
              <VideoComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'CultureListComponent'">
              <CultureListComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'StoryCarouselComponent'">
              <StoryCarouselComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TeamComponent'">
              <TeamComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ValuesComponent'">
              <ValuesComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'BenefitsComponent'">
              <BenefitsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'insitroLogoComponent'">
              <InsitroLogoComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PyramidComponent'">
              <PyramidComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'ValueChainComponent'">
              <ValueChainComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'SectionIntroComponent'">
              <SectionIntroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'MenuAnchorComponent'">
              <MenuAnchorComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ShortcutsComponent'">
              <ShortcutsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'PlatformHeroComponent'">
              <PlatformHeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  onBeforeMount,
  onMounted,
  nextTick,
  defineAsyncComponent,
} from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

import HomepageHeroComponent from '@/components/modules/HomepageHeroComponent.vue'
import HeroComponent from '@/components/modules/HeroComponent.vue'
const PageCalloutParallaxComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/PageCalloutParallaxComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const CultureComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/CultureComponent.vue').then((m) => {
      return m
    })
)
const FeaturedNewsComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/FeaturedNewsComponent.vue').then((m) => {
      return m
    })
)
const CareersComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/Careers/CareersComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const FeaturedTeamComponent = defineAsyncComponent(
  async () =>
    await import(
      '@/components/modules/FeaturedTeam/FeaturedTeamComponent.vue'
    ).then((m) => {
      return m
    })
)
const HexagonExplainerComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/HexagonExplainerComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const PipelineComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/Pipeline/PipelineComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const PageCalloutComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/PageCalloutComponent.vue').then((m) => {
      return m
    })
)
const PageCalloutHexComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/PageCalloutHexComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const GeneralContentComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/GeneralContentComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const ImageComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/ImageComponent.vue').then((m) => {
      return m
    })
)
const VideoComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/VideoComponent.vue').then((m) => {
      return m
    })
)
const CultureListComponent = defineAsyncComponent(
  async () =>
    await import(
      '@/components/modules/CultureList/CultureListComponent.vue'
    ).then((m) => {
      return m
    })
)
const StoryCarouselComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/StoryCarouselComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const TeamComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/Team/TeamComponent.vue').then((m) => {
      return m
    })
)
const ValuesComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/ValuesComponent.vue').then((m) => {
      return m
    })
)
const BenefitsComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/Benefits/BenefitsComponent.vue').then(
      (m) => {
        return m
      }
    )
)
const InsitroLogoComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/InsitroLogoComponent.vue').then((m) => {
      return m
    })
)
const PyramidComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/PyramidComponent.vue').then((m) => {
      return m
    })
)
const ValueChainComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/ValueChainComponent.vue').then((m) => {
      return m
    })
)
const SectionIntroComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/SectionIntroComponent.vue').then((m) => {
      return m
    })
)
const MenuAnchorComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/MenuAnchorComponent.vue').then((m) => {
      return m
    })
)
const ShortcutsComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/ShortcutsComponent.vue').then((m) => {
      return m
    })
)

const PlatformHeroComponent = defineAsyncComponent(
  async () =>
    await import('@/components/modules/PlatformHeroComponent.vue').then((m) => {
      // removePendingModule('PlatformHeroComponent')
      return m
    })
)

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const bgState = inject('bgState')

const page = computed(() => {
  // normalizeData('forms')
  return store.getCurrentSingleBySlug(request)
})

// const checkIfInPage = () => {
//   // console.log(page.value, 'page data')
//   if (!page.value) {
//     setTimeout(() => {
//       checkIfInPage()
//     }, 100)
//   } else {
//     page.value.acf.modules.forEach((element) => {
//       if ('HomepageHeroComponent' === element.acf_fc_layout)
//         addPendingModule('DNAmesh')

//       if ('PlatformHeroComponent' === element.acf_fc_layout)
//         addPendingModule('PlatformHeroComponent')
//     })
//   }
// }

onBeforeMount(() => getPage())

onMounted(() => {
  const anchor = router.currentRoute.hash
  nextTick(() => {
    if (anchor && document.querySelector(anchor)) {
      location.href = anchor
    }
  })
})

const getPage = async () => {
  // checkIfInPage()
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        bgState.value.isHomepage = false
        bgState.value.class = 'light'
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        bgState.value.isHomepage = true
        bgState.value.class = ''
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}

// const normalizeData = (type) => {
//   if (type) {
//     const filtered = store.getCurrentSingleBySlug(request)
//     const rawData = store.getPosts({ type: type })
//     if (filtered) {
//       for (const module of filtered.acf.modules) {
//         if (module.acf_fc_layout === type) {
//           const data = Object.entries(rawData).map((x) => x[1])
//           data.pop()
//           module[type] = data
//         }
//       }
//     }
//   }
// }
</script>

<style lang="scss">
.page-legal-wrapper {
  padding: 250px 0 130px;
}
</style>
